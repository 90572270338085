<template>
    <div class="wrongBookPage">
        <van-nav-bar
                title="错题本"
                left-arrow
                :right-text="'反馈'"
                @click-left="onClickLeft"
                @click-right="dataSelect"
        >
            <template #right>
                <img src="../../public/iconImg/icon_calendar.png" alt="">
            </template>
        </van-nav-bar>
        <van-calendar allow-same-day color="#1989fa" :min-date="new Date(2020, 0, 1)" :max-date="new Date()" v-model="showCalendar" type="range" @confirm="onConfirm"/>
        <!--        <van-popup v-model="showGradeSubject" position="bottom">-->
        <!--            <van-picker show-toolbar title="标题" :columns="columns" @change="gradeSubjectChange"-->
        <!--                        @cancel="showGradeSubject = false"-->
        <!--                        @confirm="gradeSubjectChangeHandle"/>-->
        <!--        </van-popup>-->

        <van-action-sheet v-model="showGradeSubject">
            <div class="grade_subject_content">
                <!--                <van-picker show-toolbar title="标题" :columns="columns" />-->
                <div class="sheet_item">
                    <div class="sheet_title">选择年级</div>
                    <div class="sheet_content">
                        <div class="content_line" v-for="(line,index) in $A.splitNum(this.gradeList,3)" :key="index">
                            <div class="grade line_item" :class="{active:gradeId === Number(grade.id)}"
                                 @click="gradeId = Number(grade.id)"
                                 v-for="(grade,key) in line" :key="key">{{grade.name}}
                            </div>
                        </div>
                        <!--                        <div class="grade_item" v-for="(grade,index) in this.gradeList" :key="index">{{grade.name}}</div>-->
                    </div>
                </div>
                <div class="sheet_item">
                    <div class="sheet_title">选择科目</div>
                    <div class="sheet_content">
                        <div class="content_line" v-for="(line,index) in $A.splitNum(this.subjectList,3)" :key="index">
                            <div class="subject line_item" :class="{active:subjectId === subject.subjectId}"
                                 @click="subjectSelect(subject)"
                                 v-for="(subject,key) in line" :key="subject.subjectId">
                                {{subject.name}}
                            </div>
                        </div>
                    </div>
                </div>

                <!--                <div class="sheet_title">选择科目</div>-->
                <!--                <div class="subject_select" ref="subjectSelect">-->
                <!--                    <van-tabs v-model="subjectId">-->
                <!--                        <van-tab :title="subject.name" :name="subject.subjectId" v-for="(subject,index) in subjectList" :key="index"></van-tab>-->
                <!--                    </van-tabs>-->
                <!--                </div>-->


            </div>
        </van-action-sheet>

        <div class="options_select">
            <div class="grade_subject" @click="showGradeSubject = true">
                {{currentGradeName}} - {{currentSubjectName}}
                <img src="../../public/iconImg/icon_select_down.png" alt="">
            </div>
            <div class="date_reset" @click="date = ''">
                <div class="reset_text">重置日期</div>
            </div>

        </div>

        <div class="task_type" ref="taskType">
            <van-tabs v-model="typeIndex">
                <van-tab :title="subject.title" v-for="(subject,index) in typeList"
                         @click="typeIndex = index"
                         :key="index"></van-tab>
            </van-tabs>
        </div>
        <div class="content_box">
            <van-list
                    v-model="loading"
                    :finished="finished"
                    finished-text="没有更多了"
                    :immediate-check="false"
                    @load="getData"
            >
                <div class="list_item van-clearfix" v-for="(item,index) in list" :key="index">
                    <div class="item_title">错题来源：{{item.type === '1' ? '精准作业' : '在线测评'}}</div>
                    <div class="item_text" v-html="contentFix(item.text)"></div>
                    <div class="item_options">
                        <div class="options_line" v-for="(option,index) in optionsRender(item.options)" :key="index">
                            <div class="options_index">{{option.index}} .</div>
                            <div class="options_text" v-html="contentFix(option.value)"></div>
                        </div>
                    </div>
                    <div class="item_score">
                        本题得分：<span class="student_score">{{item.score}}</span> （满分：{{item.total_score}}）
                    </div>
                    <div class="item_answer">
                        <div class="answer_line student">
                            <div class="answer_title">您的答案：</div>
                            <div class="answer_text" v-html="contentFix(item.student_answer)"></div>
                        </div>
                        <div class="answer_line correct">
                            <div class="answer_title">正确答案：</div>
                            <div class="answer_text" v-show="typeof item.answer === 'object'">
                                <div v-for="(answer,key) in item.answer" :key="key">
                                    <span v-show="item.question_type === 'empty_spaces'">（{{key}}）</span><span v-html="contentFix(answer)"></span>
                                </div>
                            </div>
<!--                            <div class="answer_text" v-else v-html="contentFix(item.answer)"></div>-->
                        </div>
                    </div>
                </div>
            </van-list>

            <van-empty v-if="list.length === 0" description="本月暂无数据"></van-empty>
        </div>
    </div>
</template>

<script>
    import UserApi from "../api/UserApi";
    import utils from "../utils/contentFix";
    import {mapGetters} from "vuex";

    export default {
        name: "WrongBook",
        data() {
            return {
                typeIndex: 0,
                typeList: [{title: '全部', examType: 0}, {title: '精准作业', examType: 1}, {title: '在线测评', examType: 8}],
                list: [],
                subjectId: 1,
                gradeId: 0,
                startData: '',
                endData: '',
                page: 1,
                pageSize: 10,
                showCalendar: false,
                showGradeSubject: false,
                date: '',
                loading: false,
                finished: false,
            }
        },
        activated() {
            this.pageInit();
        },
        computed: {
            ...mapGetters({
                //科目列表
                subjectInfo: 'common/getSubjectList',
                //年级列表
                gradeInfo: 'common/getGradeList',
            }),
            //计算当前选择的年级名称
            currentGradeName() {
                let temp = this.gradeList.filter(item => Number(item.id) === this.gradeId);
                if (temp[0]) return temp[0].name;
                return '';
            },
            //计算当前选择的科目名称
            currentSubjectName() {
                let temp = this.subjectList.filter(item => item.subjectId === this.subjectId);
                if (temp[0]) return temp[0].name;
                return '';
            },
            //计算年级列表
            gradeList() {
                console.log('gradeInfo', this.gradeInfo);
                let temp = [];
                //全部
                temp.push({
                    name: '全部年级',
                    id: '0',
                })
                //过滤高中,后期可放开
                for (let i = 0, gLen = this.gradeInfo.length; i < gLen; i++) {
                    let target = this.gradeInfo[i];
                    console.log('target', Number(target.id));
                    let gradeId = Number(target.id);
                    if (gradeId < 10) {
                        temp.push(target);
                    }

                }
                console.log('temp', temp);
                return temp;
            },
            //计算科目列表
            subjectList() {
                let temp = [];
                temp.push({
                    name: '全部科目',
                    subjectId: 0,
                })
                let subjectFilter = [];
                // console.log('this.gradeId', this.gradeId);
                switch (this.gradeId) {
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                        subjectFilter = ['语文', '数学', '英语'];
                        break;
                    case 7:
                        subjectFilter = ['语文', '数学', '英语', '政治', '历史', '地理', '生物'];
                        break;
                    case 8:
                        subjectFilter = ['语文', '数学', '英语', '物理', '政治', '历史', '地理', '生物'];
                        break;
                    case 9:
                        subjectFilter = ['语文', '数学', '英语', '政治', '物理', '化学', '历史'];
                        break;

                }
                // console.log('subjectFilter', subjectFilter);
                this.subjectInfo.forEach(item => {
                    // console.log('item', item);
                    if (!this.gradeId) {
                        temp.push({
                            name: item.name,
                            subjectId: parseInt(item.id)
                        })
                    } else {
                        if (subjectFilter.indexOf(item.name) !== -1) {
                            temp.push({
                                name: item.name,
                                subjectId: parseInt(item.id)
                            })
                        }
                    }

                })
                console.log('subjectList temp', temp);
                return temp;
            }
        },
        watch: {
            typeIndex() {
                this.initOption();
                this.getData();
            },
            date(val, oldVal) {
                if (!val) {
                    this.startData = '';
                    this.endData = '';
                }
                if (val !== oldVal) {
                    this.initOption();
                    this.getData();
                }
            },
            gradeId() {
                this.initOption();
                this.getData()
            },
            subjectId() {
                this.initOption();
                this.getData();
            }

        },
        methods: {
            //初始列表选项
            initOption(){
                this.page = 1;
                this.list = [];
            },
            subjectSelect(item) {
                // console.log('item', item);
                this.subjectId = item.subjectId;
            },
            gradeSubjectChangeHandle(e) {
                console.log(e);
                this.showGradeSubject = false
            },
            gradeSubjectChange(val) {
                console.log('val', val);
            },
            onClickLeft() {
                this.$router.go(-1);
            },
            dataSelect() {
                console.log('点击日历');
                this.showCalendar = true;
            },
            formatDate(date) {
                return `${date.getMonth() + 1}/${date.getDate()}`;
            },
            //日期确定处理函数
            onConfirm(date) {
                const [start, end] = date;
                console.log('start, end',start.getFullYear(), end)
                this.showCalendar = false;
                this.date = `${this.formatDate(start)} - ${this.formatDate(end)}`;
                console.log(' this.date', this.date);
                this.startData = start.getFullYear() + '-' + start.getMonth() + '-' + start.getDate();
                this.endData = end.getFullYear() + '-' + (end.getMonth() + 1) + '-' + end.getDate();
                // this.getData();
            },
            contentFix(str,key) {
                if (!str || str === ''){
                    return  str;
                }
                str = str.replace('<img', '<img style="max-width:100%"')
                // 内容修正
                return utils.contentFix(str)
            },
            answerRender(answer) {
                if (typeof answer === 'object') {
                    let temp = Object.values(answer);
                    return temp.join(',');
                }
                return this.contentFix(answer);
            },
            optionsRender(obj) {
                let temp = [];
                if(obj !== '{}'){
                    for (let i in obj) {
                        temp.push({
                            index: i,
                            value: obj[i],
                        })
                    }
                }
                return temp;
            },
            pageInit() {
                this.getData();
            },
            getData() {

                let params = {
                    exam_type: this.typeList[this.typeIndex].examType,
                    subject_id: this.subjectId,
                    grade_id: this.gradeId,
                    start_date: this.startData,
                    end_date: this.endData,
                    page: this.page,
                    page_size: this.pageSize,
                }
                UserApi.wrongQuestions(params).then(res => {
                    // console.log('wrongQuestions',res);
                    // 加载状态结束
                    this.loading = false;

                    if (res.success) {
                        try {
                            // this.list = res.data;
                            this.list = [...this.list, ...res.data];
                            // [...res.data].forEach(item=>{
                            //     console.log('循环',item);
                            //     if(this.list.indexOf(item) === -1) this.list.push(item);
                            // })
                            this.page++;
                            this.finished = res.data.length < this.pageSize;
                            // console.log('res.data.length', res.data.length, 'this.page_size', this.pageSize);
                            // if(res.data.length < this.pageSize){
                            //     debugger
                            // }
                        } catch (e) {
                            console.log('res.data', res.data);
                        }

                    }
                })
                this.$Z.initMathJax(document.getElementsByClassName('wrongBookPage'));
            }
        }
    }
</script>

<style lang="scss">
    .wrongBookPage {
        @extend .flex_column;

        .grade_subject_content {
            height: 480px;

            .sheet_item {
                .sheet_title {
                    text-align: left;
                    font-weight: bold;
                    font-size: 16px;
                    padding: 6px 12px;
                }

                .sheet_content {
                    padding: 0 12px;

                    .content_line {
                        @extend .flex_row_center;
                        justify-content: flex-start;

                        .line_item {
                            flex: 1;
                            max-width: calc((100% / 3) - 36px);
                            padding: 6px 12px;
                            @extend .flex_row_center;
                            border-radius: 4px;
                            border: 1px solid #ccc;
                            margin-right: 12px;
                            margin-bottom: 12px;


                            &:last-child {
                                margin-right: 0;
                            }

                            &.grade {
                                border-color: rgba(1, 159, 232, 0.3);
                                color: rgba(1, 159, 232, 1);

                                &:active, &.active {
                                    background-color: rgba(1, 159, 232, 0.1);
                                }
                            }

                            &.subject {
                                border-color: rgba(254, 103, 92, 0.3);
                                color: rgba(254, 103, 92, 1);

                                &:active, &.active {
                                    background-color: rgba(254, 103, 92, 0.1);
                                }
                            }
                        }
                    }

                }
            }
        }

        .van-nav-bar__right {
            img {
                width: 20px;
                height: 20px;
            }
        }

        .van-tab {
            font-size: 16px;

        }

        .van-tab--active {
            color: #019fe8;
        }

        .van-tabs__line {
            background-color: #019fe8;
        }

        .options_select {
            @extend .flex_row_center;
            justify-content: flex-start;
            padding: 12px;
            font-size: 16px;
            color: rgba(51, 51, 51, 1);

            .grade_subject {
                @extend .flex_row_center;
                justify-content: flex-start;
            }

            .date_reset {
                flex: 1;
                @extend .flex_row_center;
                justify-content: flex-end;

                .reset_text {
                    cursor: pointer;
                    font-size: 14px;
                    border: 1px solid #ccc;
                    border-radius: 4px;
                    padding: 0 12px;

                    &:hover {
                        background: #eee;
                    }

                    &:active {
                        transform: translate(1px 1px);
                        background: #ddd;
                    }
                }

            }

            img {
                height: 12px;
                width: 12px;
                margin-left: 4px;
            }
        }

        .task_type {
            box-shadow: 0 4px 8px 1px rgba(0, 0, 0, 0.1);
        }

        .content_box {
            @extend .flex_height_auto;
            overflow: auto;
            padding: 12px;

            .list_item {
                box-shadow: 0 4px 8px 1px rgba(0, 0, 0, 0.1);
                padding: 24px;
                border-radius: 5px;

                &:not(:last-child) {
                    margin-bottom: 12px;
                }

                .item_title {
                    font-size: 16px;
                    font-weight: bold;
                    color: rgba(51, 51, 51, 1);
                    text-align: left;

                }

                .item_text {
                    text-align: left;
                }

                .item_options {
                    margin-top: 12px;
                    @extend .flex_column;
                    justify-content: flex-start;
                    align-items: flex-start;

                    .options_line {
                        @extend .flex_row_center;

                        .options_index {
                            font-weight: bold;
                        }
                    }
                }

                .item_score {
                    font-size: 16px;
                    font-weight: bold;
                    color: rgba(51, 51, 51, 1);
                    text-align: left;
                    margin-top: 12px;

                    .student_score {
                        color: #FE675C;
                    }
                }

                .item_answer {

                    font-size: 16px;
                    font-weight: bold;
                    margin-top: 12px;

                    .answer_line {
                        @extend .flex_row_center;
                        justify-content: flex-start;
                        align-items: baseline;

                        &.correct {
                            color: #00A1E8;

                        }
                        .answer_text{
                            text-align: left;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            span{
                                white-space: break-spaces;
                            }
                        }
                        .answer_title{
                            min-width: 82px;
                        }
                    }
                }

            }
        }
    }
</style>
