/*
 * @Description:
 * @Author: Huang Qingyuan
 * @Date: 2020-09-01 14:35:09
 * @LastEditTime: 2020-09-01 14:56:48
 * @LastEditors: your name
 * @FilePath: /teacher.yuansiwei.com/src/api/utils/contentFix.js
 */
/**
 * @description: 内容修正
 * @return {type}
 * @param str
 */
export const contentFix = (str) => {
  str = str + ''
  str = imageFix(str)
  str = contentBlankFix(str)
  return str
}

/**
 * @description: 图片地址修正
 * @return {string}
 * @param str
 */
export const imageFix = (str) => {
  let res = ''
  if (str)
    res = str.replace(/(<img[^>]+src=)(['"]?\/?(images[^'"]+\.(jpg|jpeg|png|gif|bmp|tif))['"]?)([^>]*>)/ig, '$1"https://static.yuansiwei.com/$3"$5')
  return res
}
/*
* 大题小问修改大题的空格显示顺序
* @str  需要处理的字符串
* @startIndex   空格起始序号
*/
export const contentBigFix = (str, startIndex) => {
  str = contentFix(str)
    // eslint-disable-next-line no-irregular-whitespace
  // str = str.replace(/(【\d*】)?(?:&nbsp;|\s|　)*<u>(?:&nbsp;|\s|　)*(【\d*】)?(?:&nbsp;|\s|　)*<\/u>(?:&nbsp;|\s|　)*(【\d*】)?|(【\d*】)?_*(?:&nbsp;|\s|　|_)*_+(【\d*】)?_+(?:&nbsp;|\s|　|_)*_*(【\d*】)?|<span[^\>]+class=\"yswEmptys\"[^\>]*>(【\d*】)?_*(?:&nbsp;|\s|　|_)*_+(【\d*】)?_+(?:&nbsp;|\s|　|_)*_*(【\d*】)?<\/span>/ig,
  //   (matched) => {
  //     return '<span class="yswEmptys">_____【' + startIndex++ + '】_____</span>'
  //   })
  // 仅处理指定格式的空格
  str = str.replace(/<span\s*class="yswEmptys">_+【(\d+)】_+<\/span>/ig,
    (matched) => {
      return matched.replace(/\d+/i, startIndex++)
      // return '<span class="yswEmptys">_____【' + startIndex++ + '】_____</span>'
    })
  return str
}

/*
* 空格替换，主要修复 &nbsp;与英文单词连接在一起导致显示超出屏幕范围的情况
* 英文字母后面跟着的 html 空格替换成 普通空格
*/
export const contentBlankFix = (str) => {
  return str.replace(/(?:[a-z])(&nbsp;)/ig, (matched) => {
    return matched.replace('&nbsp;', ' ')
  })
}

/*
* 获取选项的最大宽度
*/
export const optionMaxWidthHeight = (dom) => {
  let maxWidth = 0
  let maxHeight = 0
  dom.childNodes.forEach((d) => {
    maxWidth = d.offsetWidth > maxWidth ? d.offsetWidth : maxWidth
    // console.log(d.innerText)
    // console.log(d.clientHeight)
    // console.log( d.offsetHeight)
    maxHeight = d.offsetHeight > maxHeight ? d.offsetHeight : maxHeight
  })
  // console.log(maxWidth, maxHeight)
  return [maxWidth, maxHeight]
}
/*
* 重设选项宽度
*/
export const setOptionWidthHeight = (dom, width, height) => {
  dom.childNodes.forEach((d) => {
    if (d.style) {
      d.style.width = width + 'px'
      d.style.height = height + 'px'
      d.style.display = 'inline-block'
      d.style.verticalAlign = 'text-bottom'
    }
  })
}

/**
 * 重新设置选项宽高
 * @param obj  vue对象
 * @param refName  vue ref 名称
 */
export const resizeOptions = (obj, refName) => {
  setTimeout(() => {
    obj.$nextTick(() => {
      let resize = (r) => {
        if (r.childNodes && r.childNodes.constructor === NodeList) {
          let parentWidth = r.offsetWidth

          let [optionWidth, optionHeight] = optionMaxWidthHeight(r)
          // console.log('option',optionWidth)
          let realWidth = parentWidth  // 最终实际宽度
          if (r.childNodes.length > 0) {
            let qWidth = (parentWidth - r.childNodes.length * 2) / r.childNodes.length  // 四分之一宽或五分之一？
            let hWidth = parentWidth / 2  // 二分之一宽
            if (optionWidth < qWidth + 2) realWidth = qWidth
            else if (optionWidth < hWidth + 2) realWidth = hWidth
            setOptionWidthHeight(r, realWidth, optionHeight)
            // console.log('rea',realWidth)
          }
        }
      }
      // 虚拟dom
      let ref = obj.$refs[refName]
      if(typeof ref != 'undefined'){
        if (ref.constructor === Array){
          ref.forEach((_r) => {
            resize(_r)
          })
        }else{
          resize(ref)
        }
      }
    })
  }, 300)
}


export default {
  contentFix,
  imageFix,
  contentBigFix,
  resizeOptions,
}
